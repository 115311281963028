<template>
  <el-card id="notCheckWorkorder">
    <header>
      <!-- <h1>待审批工单</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>申请类型</span>
            <el-input
              v-model="workOrderType"
              placeholder="请输入申请类型"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>申请人</span>
            <el-input
              v-model="userName"
              placeholder="请输入申请人"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="申请人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workOrderType"
          label="申请类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workMessage"
          label="工单内容"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="workImg" label="附件地址" align="center">
          <template v-slot="scope">
             <el-link v-for="(v, i) in  getImgArr(scope.row.workImg)" type="success" :href="v"
              style="margin-left: 10px !important;" target="_blank"
              v-if="scope.row.workImg != null && scope.row.workImg != ''">{{
                getImgArr(scope.row.workImg).length == 0 ? '无附件' : '附件' + (i + 1) }}</el-link>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="状态" align="center">
            <template v-slot="scope">
              <div :class="fontClass(scope.row.status)">
                {{ statusClass(scope.row.status) }}
              </div>
            </template>
          </el-table-column> -->
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="scope">
            <el-button type="primary" @click="planBtn(scope)" size="mini"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 审批进度 -->
    <el-dialog title="详情 & 审批进度" :visible.sync="planVisible">
      <el-descriptions
        title="工单详情信息"
        :column="2"
        border
        v-if="currentRow"
      >
        <el-descriptions-item label="申请人">{{
          currentRow.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="申请类型">{{
          currentRow.workOrderType
        }}</el-descriptions-item>
        <el-descriptions-item label="工单内容">{{
          currentRow.workMessage
        }}</el-descriptions-item>
        <el-descriptions-item label="附件">
     <!-- <el-link
            v-if="!getBool(currentRow.workImg)"
            :href="currentRow.workImg"
            target="_blank"
            type="primary"
            >{{ currentRow.workImg || '无附件' }}</el-link
          >
          <el-image
            v-else
            style="width: 100px; height: 100px"
            :src="currentRow.workImg"
            :preview-src-list="[currentRow.workImg]"
          >
          </el-image> -->
          <el-image
            v-for="(v, i) in getImgArr(currentRow.workImg)"
            style="margin-right: 6px; width: 100px; height: 100px"
            :src="v"
            :preview-src-list="getImgArr(currentRow.workImg)"
          >
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          getYMDHMS("YYYY-mm-dd HH:MM:SS", currentRow.createDate)
        }}</el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="center" style="margin-bottom: 40px"
        >审批人</el-divider
      >
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="iconPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-divider content-position="center" style="margin-top: 50px"
        >抄送人</el-divider
      >
      <div class="csr">
        <div v-for="v in planCsrData" :key="v.id" style="text-align: center">
          <img
            src="../../assets/images/yonghu.png"
            alt=""
            style="width: 30px; height: 30px"
          />
          <div style="font-size: 12px">{{ v.copyName }}</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="planVisible = false">取消</el-button>
          <el-button type="primary" @click="planSubmit()">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  workOrderUpdateWorkUserOrderType,
  workOrderSelectWorkUserOrderType,
  workOrderSelectWorkOrderTypeCopy,
  workOrderRemindWorkType,
} from "@/api/workOrder";
export default {
  data() {
    return {
      currentRow: {},
      auditRemark: null,
      workOrderTypeIda: null,
      radio: null,
      options: [
        {
          value: 0,
          label: "待审批",
        },
        {
          value: 1,
          label: "审核中",
        },
        {
          value: 2,
          label: "审批未通过",
        },
        {
          value: 3,
          label: "审批通过",
        },
      ],
      planForm: {},
      copyName: "无",
      planData: [],
      planCsrData: [],
      planVisible: false,
      // 树形控件的参数
      props: {
        label: "label",
        children: "zones",
      },
      data: [], // 选择审批人
      updataForm: {},
      orderUpdateVisible: false,
      forms: {},
      orderVisible: false,
      addForm: {},
      userName: null,
      workOrderType: null,
      createDate: null,
      status: null,
      addOrderVisible: false,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 100, //每页的数量
    };
  },
  async created() {
    this.parentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.getId = localStorage.getItem("id");
    // await this.getList();
  },
  inject: ["reload"],
  methods: {
    getImgArr(str) {
       
       if (!str) {
         return []
       } else {
         return str.split(',')
       }
     },
    getBool(url) {
      if (url) {
        let hz = url.split(".").slice(-1)[0].toLowerCase();

        // console.log("6666", hz); // 输出txt
        // console.log("6666", this.isAssetTypeAnImage(hz)); // 输出txt
        
        return this.isAssetTypeAnImage(hz);
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      );
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 查找
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.workOrderType = null;
      this.userName = null;
      this.getList();
    },
    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
      };
      let res = workOrderUpdateWorkUserOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.planVisible = false;
        this.getList();
        // this.reload();
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    iconPlan(value) {
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },
    // 审批进度
    planBtn(scope) {
      // this.planForm = scope.row;
      this.planVisible = true;
      this.currentRow = scope.row;
      this.workOrderTypeIda = scope.row.id;
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
      };
      let res = workOrderSelectWorkUserOrderType(params);
      let res1 = workOrderSelectWorkOrderTypeCopy(params);
      res.then((a) => {
        this.planData = a.data.data;
        a.data.data.forEach((v) => {
          // console.log("v.status", v.status);
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
      res1.then((b) => {
        this.planCsrData = b.data.data;
      });
    },

    // 状态分类
    statusClass(status) {
      if (status == 0) {
        return "未审核";
      } else if (status == 1) {
        return "审核中";
      } else if (status == 2) {
        return "审核未通过";
      } else if (status == 3) {
        return "审核通过";
      }
    },
    // 状态分类字体样式
    fontClass(status) {
      if (status == 0) {
        return "status0";
      } else if (status == 1) {
        return "status1";
      } else if (status == 2) {
        return "status2";
      } else if (status == 3) {
        return "status3";
      }
    },

    // 展示
    getList() {
      let params = {
        workOrderType: this.workOrderType,
        userName: this.userName,
      };
      let res = workOrderRemindWorkType(params);
      res.then((res) => {
        this.pageTotal = res.data.total;
        this.tableData = res.data.data;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}
#notCheckWorkorder {
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      // margin-top: 20px;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .status0 {
      color: #909399;
    }
    .status1 {
      color: #e6a23c;
    }
    .status2 {
      color: #f56c6c;
    }
    .status3 {
      color: #67c23a;
    }
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>