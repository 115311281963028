/**
 * approver
 * 工单审批
 */

import request from "@/utils/request";

// 工单审批
export const approverAdd = (data) => {
  return request({
    method: "post",
    url: "/approver/add",
    data,
  });
};
// 工单审批--展示
export const approverSelect = (data) => {
  return request({
    method: "post",
    url: "/approver/select",
    data,
  });
};
// 工单审批--我参与的
export const approverParticipantWorkOrderaa = (data) => {
  return request({
    method: "post",
    url: "/approver/participantWorkOrderaa",
    data,
  });
};
// 工单审批--待审批
export const approverSelectLike = (data) => {
  return request({
    method: "post",
    url: "/approver/selectLike",
    data,
  });
};
