<template>
  <el-card id="notCheckWorkorder">
    <header>
      <h2>
        <div style="display: flex; align-items: center">
          待审批工单
          <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top" v-if="roleName == '组长'">
            <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
              style="width: 30px; height: 30px; cursor: pointer" @click="showTitleText = true" />
          </el-tooltip>
        </div>
        <span v-if="roleName == '组长' && showTitleText">(待审核工单：员工提交的（请假、外出、报销、出差、退费）工单，组长可进行审核)</span>
      </h2>
      <div v-if="roleName == '企业' && showTitleText">
        <p>
          1、组长、员工提交（请假、外出、报销、出差、退费）工单，管理员可以进行审核
        </p>
        <p>2、（其他）工单：公司、组长、员工可以自己选择人员审批</p>
        <p style="color: red">
          注意：如需要工单审批流程，需和总部客服沟通，由总部完成配置方可使用
        </p>
      </div>
    </header>
    <hr>
    <main>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="请假" name="qj">
          <qj ref="qj" />
        </el-tab-pane>
        <el-tab-pane label="外出" name="wc">
          <wc ref="wc" />
        </el-tab-pane>
        <el-tab-pane label="报销" name="bx">
          <bx ref="bx" />
        </el-tab-pane>
        <el-tab-pane label="出差" name="cc">
          <cc ref="cc" />
        </el-tab-pane>
        <el-tab-pane label="退费" name="tf" v-if="parentId == 7">
          <tf ref="tf" />
        </el-tab-pane>
        <el-tab-pane label="其它" name="qt">
          <qt ref="qt" />
        </el-tab-pane>
      </el-tabs>
    </main>
  </el-card>
</template>

<script>
import qj from "@/views/workorder/modules/not/qj.vue";
import wc from "@/views/workorder/modules/not/wc.vue";
import bx from "@/views/workorder/modules/not/bx.vue";
import cc from "@/views/workorder/modules/not/cc.vue";
import tf from "@/views/workorder/modules/not/tf.vue";
import qt from "@/views/workorder/QTnotCheckWorkorder.vue";

export default {
  components: { qj, wc, bx, cc, tf, qt },
  data() {
    return {
      showTitleText: false,
      roleName: null,
      activeName: "qj",
      parentId: null,
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.$nextTick(() => {
      this.$refs.qj.getList()
    })
  },
  methods: {
    handleClick(tab, event) {
      this.$refs[tab.paneName].getList()
    }
  }
};
</script>

<style lang="less" scoped>
#notCheckWorkorder {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    >div {
      font-size: 18px;
      color: #606369;
      margin-top: 20px;
      line-height: 28px;
    }
  }
}
</style>